import bloggericon from "../../../assests/images/blogger.svg";
import calendaricon from "../../../assests/images/calendar.svg";
import chromeicon from "../../../assests/images/crome.svg";
import discordicon from "../../../assests/images/discord.svg";
import docsicon from "../../../assests/images/docs.svg";
import driveicon from "../../../assests/images/drive.svg";
import facebookicon from "../../../assests/images/facebook.svg";
import formicon from "../../../assests/images/form.svg";
import gmailicon from "../../../assests/images/gmail.svg";
import hangouticon from "../../../assests/images/hangout.svg";

import instagramicon from "../../../assests/images/instagram.svg";
import keepicon from "../../../assests/images/keeps.svg";
import kikicon from "../../../assests/images/kik.svg";
import lightroomicon from "../../../assests/images/lightroom.svg";
import lineicon from "../../../assests/images/line.svg";
import meeticon from "../../../assests/images/meet.svg";
import messengericon from "../../../assests/images/messanger.svg";
import photoshopicon from "../../../assests/images/photoshop.svg";
import pinteresticon from "../../../assests/images/pintrest.svg";
import sheeticon from "../../../assests/images/sheet.svg";
import skypeicon from "../../../assests/images/skype.svg";
import slidesicon from "../../../assests/images/slides.svg";
import snapchaticon from "../../../assests/images/snapchat.svg";
import telegramiconIcon from "../../../assests/images/telegram.svg";
import tiktokicon from "../../../assests/images/tiktok.svg";
import tindericon from "../../../assests/images/tinder.svg";
import twittericon from "../../../assests/images/twitter.svg";
import yticon from "../../../assests/images/SocialApps/yt.png";
import viber from "../../../assests/images/SocialApps/viber.png";
import vimeo from "../../../assests/images/SocialApps/vimeo.png";
import twitch from "../../../assests/images/SocialApps/twitch.png";
export const UnInstalledAppData = [
  {
    id: 1,
    title: "Snapchat",
    size: "10 MB",
    icon: snapchaticon,
  },
  {
    id: 2,
    title: "Facebook",
    size: "15 MB",
    icon: facebookicon,
  },
  {
    id: 3,
    title: "Youtube",
    size: "20 MB",
    icon: yticon,
  },
  {
    id: 4,
    title: "Twitter",
    size: "25 MB",
    icon: twittericon,
  },
  {
    id: 5,
    title: "Kik",
    size: "30 MB",
    icon: kikicon,
  },
  {
    id: 6,
    title: "Crome",
    size: "35 MB",
    icon: chromeicon,
  },
  {
    id: 7,
    title: "Pinterest",
    size: "40 MB",
    icon: pinteresticon,
  },
  {
    id: 8,
    title: "Line",
    size: "45 MB",
    icon: lineicon,
  },
  {
    id: 9,
    title: "Twitch",
    size: "50 MB",
    icon: twitch,
  },
  {
    id: 9,
    title: "Drive",
    size: "50 MB",
    icon: driveicon,
  },
  {
    id: 9,
    title: "Docs",
    size: "50 MB",
    icon: docsicon,
  },
  {
    id: 9,
    title: "Keeps",
    size: "50 MB",
    icon: keepicon,
  },
];
