import bloggericon from "../../../assests/images/blogger.svg";
import calendaricon from "../../../assests/images/calendar.svg";
import twittericon from "../../../assests/images/twitter.svg";
import tiktokicon from "../../../assests/images/tiktok.svg";

export const BlockedAppData = [
  {
    id: 1,
    title: "Tiktok",
    size: "10 MB",
    icon: tiktokicon,
  },
  {
    id: 2,
    title: "Twitter",
    size: "15 MB",
    icon: twittericon,
  },
];
