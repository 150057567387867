import {
  MdKeyboardDoubleArrowDown,
  MdOutlineMailOutline,
  MdPhoneCallback,
} from "react-icons/md";
import { HiPhoneMissedCall } from "react-icons/hi";
import CallsHead from "../../components/Calls/CallsHead";
import callicon from "../../assests/images/calls/call-fill.png";
import { VscCallOutgoing } from "react-icons/vsc";
import { IoIosCall, IoIosMailOpen } from "react-icons/io";
import { BsCurrencyDollar, BsPersonAdd } from "react-icons/bs";
import { RiDeleteBin6Line, RiUserForbidLine } from "react-icons/ri";
import { IoMicOutline, IoVideocamOutline } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { CgRemove } from "react-icons/cg";
import { AiOutlineMessage } from "react-icons/ai";
import Modal from "../../components/Shared/Modal";
import Upgrade from "../../components/Shared/Upgrade";
import Delete from "../../assests/images/delete.png";
import msg from "../../assests/images/calls/Contacts/Frame.png";
import video from "../../assests/images/calls/Contacts/Frame-1.png";
import mail from "../../assests/images/calls/Contacts/Frame-2.png";
import doller from "../../assests/images/calls/Contacts/Frame-3.png";
import callpng from "../../assests/images/calls/Contacts/Vector.png";
import mailicon from "../../assests/images/calls/Contacts/mail.png";
import locationicon from "../../assests/images/calls/Contacts/location.png";
import notes from "../../assests/images/calls/Contacts/notes.png";
import liveloca2 from "../../assests/images/liveloca2.png";
import Oval1 from "../../assests/images/profile/Oval-1.png";
import Oval2 from "../../assests/images/profile/Oval-2.png";
import Oval3 from "../../assests/images/profile/Oval-3.png";
import Oval4 from "../../assests/images/profile/Oval-4.png";
import Oval5 from "../../assests/images/profile/Oval-5.png";
import Oval6 from "../../assests/images/profile/Oval-6.png";
import Oval7 from "../../assests/images/profile/Oval-7.png";
import dp1 from "../../assests/images/profile/dp (1).png";
import dp2 from "../../assests/images/profile/dp (2).png";
import dp3 from "../../assests/images/profile/dp (3).png";
import dp4 from "../../assests/images/profile/dp (4).png";
import dp5 from "../../assests/images/profile/dp (5).png";
import dp6 from "../../assests/images/profile/dp (6).png";
import dp7 from "../../assests/images/profile/dp (7).png";
import block from "../../assests/images/calls/block.png";

import { Link } from "react-router-dom";

const ContactList = () => {
  const contactlistdata = [
    {
      img: dp7,
      name: "Alfonso Richard",
      callIcon: callicon,
      callNo: "(480) 555-0103",
    },
    {
      img: dp2,
      name: "Aurora Sellers",
      callIcon: callicon,
      callNo: "(684) 555-0102",
    },
    {
      img: dp3,
      name: "Arlene Dean",
      callIcon: callicon,
      callNo: "(201) 555-0124",
    },
    {
      img: Oval4,
      name: "Anthony Grace",
      callIcon: callicon,
      callNo: "(603) 555-0123",
    },
  ];
  const contactlistdatab = [
    {
      img: dp7,
      name: "Barrett Aschenbrenner",
      callIcon: callicon,
      callNo: "(225) 555-0118",
    },
    {
      img: dp2,
      name: "Beau Hoyland",
      callIcon: callicon,
      callNo: "(270) 555-0113",
    },
    {
      img: dp3,
      name: "Bennet Wuebker ",
      callIcon: callicon,
      callNo: "(319) 555-0115",
    },
    {
      img: Oval4,
      name: "Babette Mckenize",
      callIcon: callicon,
      callNo: "(406) 555-0120",
    },
    {
      img: Oval1,
      name: "Bentley Simank",
      callIcon: callicon,
      callNo: "(302) 555-0180",
    },
    {
      img: Oval2,
      name: "Brandon Aschenbrenner",
      callIcon: callicon,
      callNo: "(303) 555-0105",
    },
    {
      img: Oval3,
      name: "Brayden Hoyland",
      callIcon: callicon,
      callNo: "(907) 555-0101",
    },
    {
      img: Oval5,
      name: "Braxton Burks",
      callIcon: callicon,
      callNo: "(219) 555-0114",
    },
  ];
  return (
    <div>
      <CallsHead
        heading="Contacts"
        placeholder="Search by Name or Phone Number"
      />
      <Modal>
        <div className="md:border md:rounded-xl border-t  md:my-8 shadow">
          <div className="grid grid-cols-1 md:grid-cols-2 mx-2 md:mx-0">
            {/* first column */}
            <div className=" md:border-r ">
              {/* head */}
              <div className="text-lg font-bold md:p-4 p-2">
                <p>All Contacts</p>
              </div>
              <hr />
              {/* body */}
              <div className="overflow-y-scroll  no-scrollbar md:h-96 h-[calc(100%-8rem)]">
                <p className="border-b text-gray-400  p-1">A</p>
                <div className="hidden md:block">
                  <ul className="flex p-2 liborder cursor-pointer  md:bg-gray-100">
                    <li className="flex-none w-14 pt-2">
                      <img
                        className="w-10 h-10 rounded-full m-auto"
                        src={dp5}
                        alt
                      />
                    </li>
                    <li className="p-1 flex-1 w-64 font-semibold">
                      <div>
                        Allan Tran
                        <div className="text-gray-400 flex">
                          <p className="my-auto ">
                            <img src={callicon} alt="" />
                          </p>
                          <p className="ps-1">(270) 555-0117</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <Link to="/contacts/contactprofile">
                  <ul className="flex p-2 liborder cursor-pointer md:bg-gray-100 md:hidden">
                    <li className="flex-none w-14 pt-2">
                      <img
                        className="w-10 h-10 rounded-full m-auto"
                        src={dp5}
                        alt
                      />
                    </li>
                    <li className="p-1 flex-1 w-64 font-semibold">
                      <div>
                        Alice Gamage
                        <div className="text-gray-400 flex">
                          <p className="my-auto ">
                            <VscCallOutgoing />
                          </p>
                          <p className="ps-1">(308) 555-0121</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Link>

                {contactlistdata.map((info, i) => (
                  <Modal.Toggle toggleName="Update">
                    <ul key={i} className="flex p-2 liborder cursor-pointer">
                      <li className="flex-none w-14 pt-2">
                        <img
                          className="w-10 h-10 rounded-full m-auto"
                          src={info.img}
                          alt
                        />
                      </li>
                      <li className="p-1 flex-1 w-64 font-semibold">
                        <div>
                          {info.name}
                          <div className={`text-gray-500 flex font-normal`}>
                            <img
                              src={info.callIcon}
                              alt="img"
                              className="object-contain fill-gray-500  "
                            />

                            <p className="ps-1 font-semibold">{info.callNo}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Modal.Toggle>
                ))}
                <p className="border-b text-gray-400  p-1">B</p>
                {contactlistdatab.map((info, i) => (
                  <Modal.Toggle toggleName="Update">
                    <ul key={i} className="flex p-2 liborder cursor-pointer">
                      <li className="flex-none w-14 pt-2">
                        <img
                          className="w-10 h-10 rounded-full m-auto"
                          src={info.img}
                          alt
                        />
                      </li>
                      <li className="p-1 flex-1 w-64 font-semibold">
                        <div>
                          {info.name}
                          <div className={`text-gray-500 flex font-normal`}>
                            <img
                              src={info.callIcon}
                              alt="img"
                              className="object-contain fill-gray-500  "
                            />

                            <p className="ps-1 font-semibold">{info.callNo}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Modal.Toggle>
                ))}

                <div className="flex justify-center my-2">
                  <Modal.Toggle toggleName="Update">
                    <button
                      type="button"
                      className="text-gray-900 gap-1  bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center  me-2 mb-2"
                    >
                      <MdKeyboardDoubleArrowDown size={30} />
                      View More
                    </button>
                  </Modal.Toggle>
                </div>
              </div>
            </div>

            {/* second column */}
            <div className=" md:border-r hidden md:block">
              {/* head */}
              <div className="text-lg font-bold p-2 ">
                <ul className="flex p-2  ">
                  <li className="flex-none w-20 pt-2 border-none">
                    <img
                      className="w-20 h-20 rounded-full m-auto"
                      src={dp5}
                      alt="img"
                    />
                  </li>
                  <li className="p-1 flex-1 w-64 font-semibold border-none">
                    <div>
                      <p className="text-lg">Allan Taran</p>
                      <div className={`text-gray-400 flex font-normal`}>
                        <p className="my-auto ">
                          <VscCallOutgoing />
                        </p>
                        <p className="ps-1">(270) 555-0117</p>
                      </div>
                      <div className={`text-gray-400 flex font-normal`}>
                        <p className="my-auto ">
                          <IoIosMailOpen className="text-gray-400" />
                        </p>
                        <p className="ps-1">allantran@gmail.com</p>
                      </div>
                    </div>
                  </li>
                  <li className="p-1 w-20 text-gray-400 font-semibold pt-4 border-none">
                    <div className="flex gap-4 mt-6 ">
                      <Modal.Toggle toggleName="Update">
                        <div className="hidden md:block">
                          <img
                            className=" h-6  m-auto cursor-pointer"
                            src={block}
                            alt
                          />
                        </div>
                      </Modal.Toggle>
                      <Modal.Toggle toggleName="Update">
                        <div className="hidden md:block">
                          <img
                            className="h-6 cursor-pointer"
                            src={Delete}
                            alt
                          />
                        </div>
                      </Modal.Toggle>
                    </div>
                  </li>
                </ul>
              </div>
              <hr />
              {/* body */}
              <div className="overflow-y-scroll md:h-96 h-[calc(100%-8rem)] no-scrollbar">
                {/* header icons */}
                <div className="grid grid-cols-5 mx-auto p-2 gap-1 divide-x border-b overflow-x-auto">
                  <div className="flex items-center   justify-center">
                    <Modal.Toggle toggleName="Update">
                      <div className="flex gap-1 px-1 flex-col md:flex-row cursor-pointer">
                        <img className=" h-5  m-auto " src={msg} alt />
                        <p className="font-medium hidden xl:block">Message</p>
                      </div>
                    </Modal.Toggle>
                  </div>
                  <div className="flex items-center   justify-center">
                    <Modal.Toggle toggleName="Update">
                      <div className="flex gap-1 px-1 flex-col md:flex-row cursor-pointer">
                        <img className=" h-5  m-auto " src={callpng} alt />
                        <p className="font-medium hidden xl:block">Call</p>
                      </div>
                    </Modal.Toggle>
                  </div>
                  <div className="flex items-center   justify-center">
                    <Modal.Toggle toggleName="Update">
                      <div className="flex gap-1 m-auto ps-1 flex-col md:flex-row cursor-pointer">
                        <img className=" h-5  m-auto " src={video} alt />
                        <p className="font-medium ">Facetime</p>
                      </div>
                    </Modal.Toggle>
                  </div>
                  <div className="flex items-center   justify-center">
                    <Modal.Toggle toggleName="Update">
                      <div className="flex gap-1 px-1 flex-col md:flex-row cursor-pointer">
                        <img className=" h-5  m-auto " src={mail} alt />
                        <p className="font-medium hidden xl:block">Mail</p>
                      </div>
                    </Modal.Toggle>
                  </div>
                  <div className="flex items-center   justify-center">
                    <Modal.Toggle toggleName="Update">
                      <div className="flex gap-1 px-1 flex-col md:flex-row cursor-pointer">
                        <img className=" h-5  m-auto " src={doller} alt />
                        <p className="font-medium hidden xl:block">Pay</p>
                      </div>
                    </Modal.Toggle>
                  </div>
                </div>
                {/* history */}
                <ul className="flex p-2 liborder ">
                  <li className="p-1 flex-1 w-64 font-semibold ">
                    <div>
                      <div className={`text-gray-400  font-normal`}>
                        <p className="ps-1">Mobile</p>
                      </div>
                      <div className={` flex `}>
                        <p className="my-auto ">
                          <img
                            className="w-4 h-4 rounded-full m-auto"
                            src={callicon}
                            alt
                          />
                        </p>
                        <p className="ps-1">(270) 555-0117</p>
                      </div>
                    </div>
                  </li>
                  <li className="p-1 text-gray-400 font-semibold pt-2 ">
                    <div className="flex gap-4 mt-2">
                      <Modal.Toggle toggleName="Update">
                        <CgRemove
                          className="text-[#F10404] cursor-pointer"
                          size={25}
                        />
                      </Modal.Toggle>
                    </div>
                  </li>
                </ul>
                <ul className="flex p-2 liborder ">
                  <li className="p-1 flex-1 w-64 font-semibold ">
                    <div>
                      <div className={`text-gray-400  font-normal`}>
                        <p className="ps-1">business</p>
                      </div>
                      <div className={` flex `}>
                        <p className="my-auto ">
                          <img
                            className="w-4 h-4 rounded-full m-auto"
                            src={callicon}
                            alt
                          />
                        </p>
                        <p className="ps-1">(270) 555-0118</p>
                      </div>
                    </div>
                  </li>
                  <li className="p-1 text-gray-400 font-semibold pt-2 ">
                    <div className="flex gap-4 mt-2">
                      <Modal.Toggle toggleName="Update">
                        <CgRemove
                          className="text-[#F10404] cursor-pointer"
                          size={25}
                        />
                      </Modal.Toggle>
                    </div>
                  </li>
                </ul>
                <ul className="flex p-2  liborder">
                  <li className="p-1 flex-1 w-64 font-semibold ">
                    <div>
                      <div className={`text-gray-400  font-normal`}>
                        <p className="ps-1">home</p>
                      </div>
                      <div className={` flex `}>
                        <p className="my-auto ">
                          <img
                            className="w-4 h-4 rounded-full m-auto"
                            src={callicon}
                            alt
                          />
                        </p>
                        <p className="ps-1">(270) 478-0112</p>
                      </div>
                    </div>
                  </li>
                  <li className="p-1 text-gray-400 font-semibold pt-2 ">
                    <div className="flex gap-4 mt-2">
                      <Modal.Toggle toggleName="Update">
                        <CgRemove
                          className="text-[#F10404] cursor-pointer"
                          size={25}
                        />
                      </Modal.Toggle>
                    </div>
                  </li>
                </ul>
                <ul className="flex p-2 liborder ">
                  <li className="p-1 flex-1 w-64 font-semibold ">
                    <div>
                      <div className={`text-gray-400  font-normal`}>
                        <p className="ps-1">Email</p>
                      </div>
                      <div className={` flex `}>
                        <p className="my-auto ">
                          <img className="w-4 h-4  m-auto" src={mailicon} alt />
                        </p>
                        <p className="ps-1">allantran@gmail.com</p>
                      </div>
                    </div>
                  </li>
                  <li className="p-1 text-gray-400 font-semibold pt-2 ">
                    <div className="flex gap-4 mt-2">
                      <Modal.Toggle toggleName="Update">
                        <CgRemove
                          className="text-[#F10404] cursor-pointer"
                          size={25}
                        />
                      </Modal.Toggle>
                    </div>
                  </li>
                </ul>
                <ul className="flex p-2 liborder ">
                  <li className="p-1 flex-1 w-64 font-semibold ">
                    <div>
                      <div className={`text-gray-400  font-normal`}>
                        <p className="ps-1">Business Email</p>
                      </div>
                      <div className={` flex `}>
                        <p className="my-auto ">
                          <img className="w-4 h-4  m-auto" src={mailicon} alt />
                        </p>
                        <p className="ps-1">allantran@gmail.com</p>
                      </div>
                    </div>
                  </li>
                  <li className="p-1 text-gray-400 font-semibold pt-2 ">
                    <div className="flex gap-4 mt-2">
                      <Modal.Toggle toggleName="Update">
                        <CgRemove
                          className="text-[#F10404] cursor-pointer"
                          size={25}
                        />
                      </Modal.Toggle>
                    </div>
                  </li>
                </ul>
                {/* <ul className="flex p-2 liborder ">
                  <li className="p-1 flex-1 w-64 font-semibold ">
                    <div>
                      <div className={`text-gray-400  font-normal`}>
                        <p className="ps-1">home</p>
                      </div>
                      <div className={` flex  mb-2`}>
                        <p className="mt-1 ">
                          <img
                            className=" h-4  m-auto "
                            src={locationicon}
                            alt
                          />
                        </p>
                        <p className="ps-1">
                          2118 Thornridge <br /> Cir,Syracuse, <br />
                          Connecticut 35624
                        </p>
                      </div>
                    </div>
                    <div>
                      <img
                        src={liveloca2}
                        alt=""
                        className="h-52 object-cover rounded-xl w-full mx-4"
                      />
                    </div>
                  </li>
                  <li className="p-1 text-gray-400 font-semibold pt-2 ">
                    <div className="flex gap-4 mt-2">
                      <Modal.Toggle toggleName="Update">
                        <CgRemove
                          className="text-[#F10404] cursor-pointer"
                          size={25}
                        />
                      </Modal.Toggle>
                    </div>
                  </li>
                </ul> */}
                <ul className="flex p-2 liborder ">
                  <li className="p-1 flex-1 w-64 font-semibold ">
                    <div>
                      <div className={`text-gray-400  font-normal`}>
                        <p className="ps-1">Notes</p>
                      </div>
                      <div className={` flex `}>
                        <img className="w-4 h-4 mt-1" src={notes} alt />

                        <p className="ps-1">
                          We are going to hold a rally on Saturday. <br />
                          <p className="my-1">Items to prepare:</p>
                          <br />
                          lemonade dispenserPicnic table clothExtra outdoor
                          lights,elastic balls and heavy toys, large
                          refrigerators for drinks, loudspeakers for music
                          <br />
                          <p className="my-[2px]">Invitation list:</p>
                          <br />
                          1.The Ulrick family
                          <br />
                          2. The Tilmon family
                          <br />
                          3.o'Malley
                          <br />
                          4. Swanson Family
                          <br />
                          5. Lee's Family
                          <br />
                          6. Shaw FamilyThe
                          <br />
                          7 Dean family
                          <br />
                          8. The Roberts
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="p-1 text-gray-400 font-semibold pt-2 ">
                    <div className="flex gap-4 mt-2">
                      <Modal.Toggle toggleName="Update">
                        <CgRemove
                          className="text-[#F10404] cursor-pointer"
                          size={25}
                        />
                      </Modal.Toggle>
                    </div>
                  </li>
                </ul>

                <div className="flex p-2 justify-between">
                  <div aria-label="Page navigation example">
                    <div className="hidden md:block">
                      <ul className="inline-flex -space-x-px text-base h-10">
                        <Modal.Toggle toggleName="Update">
                          <li>
                            <a
                              href="#"
                              className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 "
                            >
                              <FaArrowLeft />
                            </a>
                          </li>
                        </Modal.Toggle>
                        <Modal.Toggle toggleName="Update">
                          <li>
                            <a
                              href="#"
                              className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                            >
                              1
                            </a>
                          </li>
                        </Modal.Toggle>
                        <Modal.Toggle toggleName="Update">
                          <li>
                            <a
                              href="#"
                              className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                            >
                              ...
                            </a>
                          </li>
                        </Modal.Toggle>
                        <Modal.Toggle toggleName="Update">
                          <li>
                            <a
                              href="#"
                              aria-current="page"
                              className="flex items-center justify-center px-4 h-10 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 "
                            >
                              10
                            </a>
                          </li>
                        </Modal.Toggle>
                        <Modal.Toggle toggleName="Update">
                          <li>
                            <a
                              href="#"
                              className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 "
                            >
                              <FaArrowRight />
                            </a>
                          </li>
                        </Modal.Toggle>
                      </ul>
                    </div>
                  </div>

                  <div className="m-auto md:m-0">
                    <Modal.Toggle toggleName="Update">
                      <button
                        type="button"
                        className="text-gray-900 gap-1  bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center  me-2 mb-2"
                      >
                        <MdKeyboardDoubleArrowDown size={30} />
                        View More
                      </button>
                    </Modal.Toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Window windowName="Update">
          <Upgrade />
        </Modal.Window>
      </Modal>
    </div>
  );
};

export default ContactList;
