export const LocationData = [
  {
    title: "S Main St",
    des: "38.0530192-102.1240109  ",
  },
  // {
  //   title: "375 James st, New Haven, CT 06513",
  //   des: "41.3130335, -72.9037375"
  // },
  // {
  //   title: "375 James st, New Haven, CT 06513",
  //   des: "41.3130335, -72.9037375"
  // },
  // {
  //   title: "375 James st, New Haven, CT 06513",
  //   des: "41.3130335, -72.9037375"
  // },
  // {
  //   title: "375 James st, New Haven, CT 06513",
  //   des: "41.3130335, -72.9037375"
  // }
];
